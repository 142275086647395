export class UserInfo {

    constructor(
        public id: string,
        public teamId: string,
        public email: string,
        public firstName: string,
        public lastName: string,
        public roles: string[],
    ) { }

    static adapt(objLiteral: UserInfo): UserInfo {
        if (objLiteral == null) {
            return null;
        }
        return new UserInfo(
            objLiteral.id,
            objLiteral.teamId,
            objLiteral.email,
            objLiteral.firstName,
            objLiteral.lastName,
            objLiteral.roles
        );
    }
}
