import { PendingOperation } from './pending-operation.model';

export class UserCacheEntry {

    constructor(
        public email: string,
        public password: string,
        public firstName: string,
        public lastName: string,
        public roles: string[],
        public teamId: string,
        public timestampLastLogin: Date,
        public pendingOperations: PendingOperation[],
    ) { }

    static adapt(objLiteral: UserCacheEntry): UserCacheEntry {
        if (objLiteral == null) {
            return null;
        }
        return new UserCacheEntry(
            objLiteral.email,
            objLiteral.password,
            objLiteral.firstName,
            objLiteral.lastName,
            objLiteral.roles,
            objLiteral.teamId,
            new Date(objLiteral.timestampLastLogin),
            objLiteral.pendingOperations.map(pendingOperation => PendingOperation.adapt(pendingOperation)),
        );
    }
}
