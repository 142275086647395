export class ParentChildLopInformation {

    constructor(
        public positionNumber: number,
        public windFarm: string,
        public windTurbine: string,
    ) { }

    static adapt(objLiteral: ParentChildLopInformation): ParentChildLopInformation {
        if (objLiteral == null) {
            return null;
        }
        return new ParentChildLopInformation(
            objLiteral.positionNumber,
            objLiteral.windFarm,
            objLiteral.windTurbine,
        );
    }
}
