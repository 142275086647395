export enum OperationType {
    ADD_LOP,
    REPORT_DISCREPANCY,
    PROGRESS,
    SET_STATE,
    REPORT_ALREADY_FINISHED,
}

export class PendingOperation {
    timestamp: Date;

    constructor(
        public userId: string,
        public type: OperationType,
        public payload: object,
    ) { }

    static adapt(objectLiteral: PendingOperation): PendingOperation {
        const pendingOperation = new PendingOperation(
            objectLiteral.userId,
            objectLiteral.type,
            objectLiteral.payload,
        );
        pendingOperation.timestamp = new Date(objectLiteral.timestamp);
        return pendingOperation;
    }

    public getTypeString(): string {
        switch (this.type){
            case OperationType.ADD_LOP:
                return 'ADD_LOP';
            case OperationType.PROGRESS:
                return 'PROGRESS';
            case OperationType.REPORT_ALREADY_FINISHED:
                return 'REPORT_ALREADY_FINISHED';
            case OperationType.REPORT_DISCREPANCY:
                return 'REPORT_DISCREPANCY';
            case OperationType.SET_STATE:
                return 'SET_STATE';
        }
    }
}
