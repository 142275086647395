export class Step {

    constructor(
        public id: string,
        public description: string,
        public optional: boolean,
        public checkbox: boolean) {
    }

    static adapt(objLiteral: Step): Step {
        if (objLiteral == null) {
            return null;
        }
        return new Step(
            objLiteral.id,
            objLiteral.description,
            objLiteral.optional,
            objLiteral.checkbox,
        );
    }
}
