export class FinishedDiscrepancyDetails {

    constructor(
        public id: string,
        public report: string,
        public resolveComment: string,
        public reporterId: string,
        public resolverId: string,
        public timestamp: Date,
        public lopMeanwhileModified: boolean
    ) { }

    static adapt(objLiteral: FinishedDiscrepancyDetails): FinishedDiscrepancyDetails {
        if (objLiteral == null) {
            return null;
        }
        return new FinishedDiscrepancyDetails(
            objLiteral.id,
            objLiteral.report,
            objLiteral.resolveComment,
            objLiteral.reporterId,
            objLiteral.resolverId,
            new Date(objLiteral.timestamp),
            objLiteral.lopMeanwhileModified,
        );
    }
}
