import { Lop } from './lop.model';
import { PendingOperation } from './pending-operation.model';

export class TeamCacheEntry {

    constructor(
        public lops: Lop[],
        public timestampLastLopsUpdate: Date,
        public pendingOperations: PendingOperation[],
    ) { }

    static adapt(objLiteral: TeamCacheEntry): TeamCacheEntry {
        if (objLiteral == null) {
            return null;
        }
        return new TeamCacheEntry(
            objLiteral.lops ? objLiteral.lops.map(lop => Lop.adapt(lop)) : [],
            new Date(objLiteral.timestampLastLopsUpdate),
            objLiteral.pendingOperations.map(pendingOperation => PendingOperation.adapt(pendingOperation)),
        );
    }
}
