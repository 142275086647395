import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(
        private plt: Platform,
        ) {
    }

    isApp(): boolean {
        return this.plt.is('capacitor');
    }

    isBrowser(): boolean {
        return !this.isApp();
    }
}
