import { __assign } from 'tslib';

import * as _ from "lodash";
import { ModalController, ToastController } from '@ionic/angular';
import { Filterable } from './filterable';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceService } from './device-service';

/**
 * Class that holds a set of reusable and generic utility functions.
 */
export class Utils {

  static HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

  /**
   * Extends the cloneDeep-method from lodash by preserving types.
   *
   * Reference: https://github.com/lodash/lodash/issues/1726#issuecomment-168088545
   */
  static deepClone(obj: any) {
    return _.partialRight(_.cloneDeep, this.deepClonePartial)(obj);
  }

  private static deepClonePartial(value) {
    if (_.isObject(value) && !_.isArray(value)) {
      return _.cloneDeep(_.toPlainObject(value));
    }
  }

  /**
   * Returns true for two objects being truly equal by value.
   *
   * Reference: https://gist.github.com/nicbell/6081098
   */
  static isDeepEqual(obj1, obj2): boolean {
    if (obj1 == null) {
      if (obj1 === null) {
        return obj2 === null;
      } else {
        return typeof obj2 === 'undefined';
      }
    }
    if (obj2 == null) {
      return false;
    }
    if (obj1 instanceof Map) {
      if (!(obj2 instanceof Map)) {
        return false;
      }
      return this.areMapsEqual(obj1, obj2);
    }
    if (obj1 instanceof Set) {
      if (!(obj2 instanceof Set)) {
        return false;
      }
      return this.areSetsEqual(obj1, obj2);
    }
    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

      switch (typeof (obj1[p])) {
        //Deep compare objects
        case 'object':
          if (!this.isDeepEqual(obj1[p], obj2[p])) return false;
          break;
        //Compare function code
        case 'function':
          if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
          break;
        //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
      }
    }

    //Check object 2 for any extra properties
    for (var p in obj2) {
      if (typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
  };

  /**
   * Returns true for two Maps being equal
   *
   * Reference: https://stackoverflow.com/questions/35948335/how-can-i-check-if-two-map-objects-are-equal
   */
  static areMapsEqual(map1: Map<any, any>, map2: Map<any, any>): boolean {
    var testVal;
    if (map1.size !== map2.size) {
      return false;
    }
    for (var [key, val] of map1) {
      testVal = map2.get(key);
      // in cases of an undefined value, make sure the key
      // actually exists on the object so there are no false positives
      if (testVal !== val || (testVal === undefined && !map2.has(key))) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns true for two Sets being equal
   *
   * Reference: https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
   */
  static areSetsEqual(as: Set<any>, bs: Set<any>): boolean {
    return _.isEqual(as, bs);
  }

  /**
   * @description
   * Takes an Array<V>, and a grouping function,
   * and returns a Map of the array grouped by the grouping function.
   *
   * @param list An array of type V.
   * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
   *                  K is generally intended to be a property key of V.
   *
   * @returns Map of the array grouped by the grouping function.
   */
  //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  //    const map = new Map<K, Array<V>>();
  static groupBy<T>(list: T[], keyGetter: (item: T) => any, sorter: (a: T, b: T) => number = null): T[][] {
    const map = new Map();
    list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
    });
    let groups = Array.from(map.values());
    if (sorter) {
      groups = groups.sort((a, b) => sorter(a[0], b[0]));
    }
    return groups;
  }

  static splitArray<T>(array: T[], each: number, filler: T = null): T[][] {
    const splitted = [];
    for (let i = 0; i < array.length; i += each) {
      splitted.push(array.slice(i, Math.min(i + each, array.length)));
    }
    if (filler) {
      const lastElement = splitted[splitted.length - 1];
      while (lastElement.length < each) {
          lastElement.push(filler);
      }
    }
    return splitted;
  }

  /**
   * Converts an object literal into an instance of the given type.
   * @param obj object literal
   * @param type type to instantiate
   */
  static adaptObjectLiteral(obj: object, type: any) {
    if (obj == null) {
      return null;
    }
    const instance = new type();
    _.assign(instance, obj)
    return instance;
  }

  /**
   * Helper method to show a toast.
   * @param toastController toast controller to use
   * @param message message to display
   */
  static async showToast(device: DeviceService, toastController: ToastController, message: string) {
    const toast = await toastController.create({
        message: message,
        position: device.isApp() ? 'bottom' : 'top',
        duration: 2500,
    });
    toast.present();
  }

  /**
   * Dismisses all modals.
   * @param modalController modal controller to use
   */
  static async dismissAll(modalController: ModalController): Promise<void> {
    let modal: HTMLIonModalElement;
    while ((modal = await modalController.getTop())) {
        modal.dismiss();
    }
  }

  static isEmpty(str: string): boolean {
    return (!str || 0 === str.length);
  }

  /**
   * Filters the given collection so that every part of the query must be contained by each element (case insensitive).
   * @param query query to filter for
   * @param collection the collection to filter
   */
  static filter<T extends Filterable>(query: string, collection: T[]): T[] {
      if (query === '') {
        return collection;
      }
      const queries = query.toUpperCase().split(' ');
      return collection.filter(t => {
        for (const q of queries) {
          if (!t.filter(q)) {
            return false;
          }
        }
        return true;
      });
  }

  /**
   * Converts an image URI to a file blob.
   * @param dataURI URI of the image including the base64-data
   * @param imageName name of the image file
   * @returns A file blob of the image
   */
  static imageUriToBlobFile(dataURI: string): File {
    const imageBlob = this.dataURItoBlob(dataURI);
    const imageFile = new File([imageBlob], 'photo.jpeg');
    return imageFile;
  }

  /**
   * Converts an image URI to a blob.
   * @param dataURI URI of the image including the base64-data
   * @returns A blob of the image
   */
  private static dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  static blobToImageUri(blob: Blob): Promise<string> {
    var reader = new FileReader();
    const promise = new Promise<string>(resolve => {
      reader.onloadend = function() {
        var base64data = reader.result as string;
        resolve(base64data);
      };
    });
    reader.readAsDataURL(blob);
    return promise;
  }

  static dataUriToBase64String(dataURI: string): string {
    return dataURI.split(',')[1];
  }

  static uuid(): string {
    let uuidValue = "", k: number, randomValue: number;
    for (k = 0; k < 32; k++) {
      randomValue = Math.random() * 16 | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "-"
      }
      uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
    }
    return uuidValue;
  }
}
