import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { AuthorizeService, AuthorizeStatus } from './authorize.service';
import { Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Utils } from 'src/app/shared/utils';
import { OfflineModeService } from 'src/app/shared/offline-mode.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/shared/device-service';

/**
 * HTTP Interceptor that redirects unauthorized users to the login page 
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {

  authorizeService: AuthorizeService;
  refreshSessionInProgress = false;

  translateService: TranslateService = null;

  sessionRefreshedSource = new Subject();
  sessionRefreshed$ = this.sessionRefreshedSource.asObservable();

  constructor(
    private device: DeviceService,
    private toastController: ToastController,
    private injector: Injector,
    private modalController: ModalController,
    private router: Router) {
      setTimeout(() => this.translateService = injector.get(TranslateService));
    }

  /**
   * Refreshes the session if possible. Can be executed multiple times at once and will still execute only one session refreshing.
   */
  refreshSession(): Observable<any> {
    if (this.refreshSessionInProgress) {
      return new Observable(observer => {
        this.sessionRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshSessionInProgress = true;

      console.log('Trying to automatically refresh the session.');
      return from(this.authorizeService.automaticSignIn()).pipe(
        tap(() => {
          console.log('Successfully refreshed the session automatically.');
          this.refreshSessionInProgress = false;
          this.sessionRefreshedSource.next();
        }),
        catchError((error) => {
          console.log('Failed to automatically refresh the session:', error);
          this.refreshSessionInProgress = false;
          this.logout();
          return throwError(error);
        }));
    }
  }

  /**
   * Logs out the user locally and redirects him to the login-page.
   */
  logout() {
      this.authorizeService.localSignOut();
      Utils.dismissAll(this.modalController);
      this.router.navigate(['login']);
  }

  /**
   * Handles the error case for every HTTP request made. Will try to refresh the session on 401.
   * Multiple requests will be put on hold while the session is refreshed and retried after that.
   */
  handleResponseError(error: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler): Observable<any> {
    // Invalid token error
    if (error.status === 401) {
      if (this.device.isBrowser()) {
        return throwError(error);
      }
      return this.refreshSession().pipe(
        switchMap(() => {
          console.log('Retrying the request after refreshing the login at ' + request.url);
          request = this.addAuthentication(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {
            this.logout();
            return throwError(error);
          }
        }));
    }

    // Access denied error
    else if (error.status === 403) {
      this.logout();
    }

    return throwError(error);
  }

  /**
   * Enables credentials (i.e. cookies) for requests. If a 401 is returned the session is tried to be refreshed,
   * otherwise the user will be logged out locally and redirected to the login-page.
   * Workaround: Due to the InAppBrowser not deleting the cookie on logout, the cookie will be suppressed
   * when logged out locally and no last logged in user being available.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const offlineModeService = this.injector.get(OfflineModeService);
    return from(offlineModeService.getLastLoggedInUser()).pipe(switchMap(lastLoggedInUser => {

      if (this.authorizeService == null) {
        this.authorizeService = this.injector.get(AuthorizeService);
      }
      if (lastLoggedInUser != null || this.authorizeService.getStatus() !== AuthorizeStatus.LOGGED_OUT) {
        request = this.addAuthentication(request);
      } else {
        console.log('Skipping enabling cookies of request due to being logged out at: ' + request.url);
      }

      console.log('Request at ' + request.url);
      return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleResponseError(error, request, next)));
    }));
  }

  /**
   * Set "withCredentials" so that the server is allowed to set session cookies
   */
  private addAuthentication(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      withCredentials: true,
    });
  }
}
