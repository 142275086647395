export class EnvironmentInfo {

    unit?: string;

    constructor(
        public type: string,
        public value: number,
        public productionStep: string,
        public timestamp: Date,
    ) { }

    static adapt(objLiteral: EnvironmentInfo): EnvironmentInfo {
        if (objLiteral == null) {
            return null;
        }
        return new EnvironmentInfo(
            objLiteral.type,
            objLiteral.value,
            objLiteral.productionStep,
            new Date(objLiteral.timestamp),
        );
    }
}
