export class WindFarmInfo {

    constructor(
        public windFarmsAndTurbines: Map<string, string[]>,
    ) { }

    static adapt(objLiteral: WindFarmInfo): WindFarmInfo {
        if (objLiteral == null) {
            return null;
        }
        return new WindFarmInfo(
            new Map(Object.entries(objLiteral.windFarmsAndTurbines))
        );
    }

    toObject(): object {
        const literalObj = {
            windFarmsAndTurbines: {},
        };
        this.windFarmsAndTurbines.forEach((value, key) => (literalObj.windFarmsAndTurbines[key] = value));
        return literalObj;
    }
}
