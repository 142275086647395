export class Photo {

    // Progress of the upload. Will be 1.0 if uploaded completely
    progress: number = 0;

    // Path on mobile devices at which the image was persisted. NULL if not persisted yet.
    persistedAtPath: string;

    // Remote URL, filesystem path or Base64-ImageUri that can be used in e.g. <img>-tags
    webviewPath: string;

    constructor(
        // ID in the remote database of this photo. NULL if not persisted there yet.
        public id: string = null,
        // Base64-ImageUri of the photo
        public data: string = null,
        // Production step for which this photo was taken
        public productionStep: string = null,
        // Timestamp in UTC of when the photo was taken
        public timestamp: Date = null,
    ) {
        this.webviewPath = data;

        if (id != null) {
            this.progress = 1;
        }
    }

    isPersisted(): boolean {
        return this.id != null;
    }

    isAttached(): boolean {
        return this.timestamp != null;
    }

    static adapt(objectLiteral: Photo): Photo {
        const photo = new Photo(
            objectLiteral.id,
            objectLiteral.data,
            objectLiteral.productionStep,
            new Date(objectLiteral.timestamp),
        );
        // Keep default value from constructor if not set in object literal
        if (objectLiteral.progress != null) {
            photo.progress = objectLiteral.progress;
        }
        photo.persistedAtPath = objectLiteral.persistedAtPath;
        photo.webviewPath = objectLiteral.webviewPath;
        return photo;
    }
}
