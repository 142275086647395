import { Step } from './step.model';

export class Action {

    constructor(
        public description: string,
        public requiredEnvironmentInfo: string[],
        public steps: Step[],
    ) { }

    static adapt(objLiteral: Action): Action {
        if (objLiteral == null) {
            return null;
        }
        return new Action(
            objLiteral.description,
            objLiteral.requiredEnvironmentInfo,
            objLiteral.steps?.map(step => Step.adapt(step)),
        );
    }
}
