export class TranslatableError extends Error {
    constructor(
        public messageKey: string,
        public messageParameters?: Record<string, string>,
    ) {
        super(messageKey);
    }

    static adapt(objLiteral: TranslatableError): TranslatableError {
        if (!objLiteral || !objLiteral.hasOwnProperty('messageKey')) {
            return null;
        }
        return new TranslatableError(objLiteral.messageKey, objLiteral.messageParameters);
    }
}
