import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DeviceService } from "../shared/device-service";
import { Utils } from "../shared/utils";
import { TranslatableError } from "./translatable-error.model";

/**
 * The HttpErrorInterceptor class.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  translateService: TranslateService = null;

  constructor(
    private device: DeviceService,
    private toastController: ToastController,
    injector: Injector) {
      setTimeout(() => this.translateService = injector.get(TranslateService));
    }

  /**
   * Intercepts HTTP error responses and maps to the internal error type.
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      const translatableError = TranslatableError.adapt(err.error);

      // Check if the error response is a translatable one
      if (translatableError) {
        // Show translatable error message including its interpolation parameters
        this.translateService.get(translatableError.messageKey, translatableError.messageParameters).toPromise<string>().then(message => {
          Utils.showToast(this.device, this.toastController, message);
        });
        return throwError(translatableError);

      } else if (![0,401,403].includes(err.status)) {
        // Show bad request error message for all expected bad request errors (expected are those with a string as body)
        if (err.status === 400 && typeof(err.error) === 'string' && err.error.length > 0) {
          this.translateService.get(err.error).toPromise<string>().then(message => {
            Utils.showToast(this.device, this.toastController, message);
          });

        // Show generic error message for all other errors (except for 0, 401 and 403 since they are handled elsewhere)
        } else {
          this.translateService.get('UNEXPECTED_ERROR_OCCURED').toPromise<string>().then(message => {
            Utils.showToast(this.device, this.toastController, message);
          });
        }
      }

      // Return http status message error
      return throwError(err);
    }));
  }
}
