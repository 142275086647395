import { Action } from './action.model';

export class WorkSteps {

    constructor(
        public environmentInfo: Map<string, string>,
        public actions: Map<string, Action>,
        public damageSets: Map<string, string[]>,
        public measures: Map<string, string>,
    ) { }

    public static adapt(objLiteral: WorkSteps): WorkSteps {
        if (objLiteral == null) {
            return null;
        }
        const actions = new Map<string, Action>();
        for (const entry of Object.entries(objLiteral.actions)) {
            actions.set(entry[0], Action.adapt(entry[1]));
        }
        return new WorkSteps(
            new Map(Object.entries(objLiteral.environmentInfo)),
            actions,
            new Map(Object.entries(objLiteral.damageSets)),
            new Map(Object.entries(objLiteral.measures)),
        );
    }

    toObject(): object {
        const workStepsObj = {
            environmentInfo: {},
            actions: {},
            damageSets: {},
            measures: {},
        };
        this.environmentInfo.forEach((value, key) => (workStepsObj.environmentInfo[key] = value));
        this.actions.forEach((value, key) => (workStepsObj.actions[key] = value));
        this.damageSets.forEach((value, key) => (workStepsObj.damageSets[key] = value));
        this.measures.forEach((value, key) => (workStepsObj.measures[key] = value));
        return workStepsObj;
    }
}
