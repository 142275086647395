import { Photo } from './photo.model';
import { FinishedDiscrepancyDetails } from './finished-discrepancy-details.model';
import { EnvironmentInfo } from './environment-info.model';
import { ParentChildLopInformation } from './parent-child-lop-information.model';

export class Lop {
    translatedPart: string;
    translatedDamageType: string;
    translatedStatus: string;
    currentStep: number;
    selectedCheckboxes: number[];
    environmentInfos: EnvironmentInfo[];
    noMeasureRequiredReportedBy: string;
    noMeasureRequiredReportedAt: Date;
    protocolTitle: string;
    finishedDate: Date;
    parent: ParentChildLopInformation;
    child: ParentChildLopInformation;

    constructor(
      public id: string,
      public positionNumber: number,
      public windFarm: string,
      public windTurbine: string,
      public part: string,
      public damageType: string,
      public segment: string,
      public axis: string,
      public length: number,
      public width: number,
      public depth: number,
      public measure: string,
      public comment: string,
      public status: string,
      public teamId: string,
      public photos: Photo[],
      public finishedDiscrepancies: FinishedDiscrepancyDetails[],
      public stepTimestamps: Date[]
    ) { }

    static adapt(objLiteral: Lop): Lop {
      if (objLiteral == null) {
          return null;
      }
      const finishedDiscrepancies = objLiteral.finishedDiscrepancies
        ?.map(fdd => FinishedDiscrepancyDetails.adapt(fdd))
        ?.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      const lop = new Lop(
        objLiteral.id,
        objLiteral.positionNumber,
        objLiteral.windFarm,
        objLiteral.windTurbine,
        objLiteral.part,
        objLiteral.damageType,
        objLiteral.segment,
        objLiteral.axis,
        objLiteral.length,
        objLiteral.width,
        objLiteral.depth,
        objLiteral.measure,
        objLiteral.comment,
        objLiteral.status,
        objLiteral.teamId,
        objLiteral.photos?.map(photo => Photo.adapt(photo)).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        finishedDiscrepancies,
        objLiteral.stepTimestamps?.map(timestamp => new Date(timestamp)),
      );

      lop.translatedPart = objLiteral.translatedPart;
      lop.translatedDamageType = objLiteral.translatedDamageType;
      lop.translatedStatus = objLiteral.translatedStatus;
      lop.currentStep = objLiteral.currentStep;
      lop.selectedCheckboxes = objLiteral.selectedCheckboxes;
      lop.environmentInfos = objLiteral.environmentInfos
        ?.map(envInfo => EnvironmentInfo.adapt(envInfo))
        ?.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      lop.noMeasureRequiredReportedBy = objLiteral.noMeasureRequiredReportedBy;
      if (objLiteral.noMeasureRequiredReportedAt) {
        lop.noMeasureRequiredReportedAt = new Date(objLiteral.noMeasureRequiredReportedAt);
      }
      lop.protocolTitle = objLiteral.protocolTitle;
      if (objLiteral.finishedDate) {
        lop.finishedDate = new Date(objLiteral.finishedDate);
      }
      lop.parent = ParentChildLopInformation.adapt(objLiteral.parent);
      lop.child = ParentChildLopInformation.adapt(objLiteral.child);

      return lop;
    }

    isValid(): boolean {
        if (this.positionNumber == -1) {
            return false
        }
        if (!this.windFarm || this.windFarm == "") {
            return false;
        }
        if (!this.windTurbine || this.windTurbine == "") {
            return false;
        }
        if (!this.part  || this.part == "") {
            return false;
        }
        if (!this.damageType || this.damageType == "") {
            return false;
        }
        if (!this.segment || this.segment == "") {
            return false;
        }
        if (!this.axis || this.axis == "") {
            return false;
        }
        if (this.length == -1) {
            return false;
        }
        if (this.width == -1) {
            return false;
        }
        if (this.depth == -1) {
            return false;
        }
        if (!this.axis || this.axis == "") {
            return false;
        }
        if (!this.photos || this.photos.length == 0) {
            return false;
        }
        if (!this.measure || this.measure == "") {
            return false;
        }
        return true;
    }
}
