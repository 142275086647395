import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from 'src/app/models/user-info.model';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { OfflineModeService } from 'src/app/shared/offline-mode.service';
import { Platform, ToastController } from '@ionic/angular';
import { Utils } from 'src/app/shared/utils';
import { UserCacheEntry } from 'src/app/models/user-cache-entry.model';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/shared/device-service';

export enum AuthorizeStatus {
  LOGGED_IN,
  LOGGED_OUT,
  LOGGING_IN,
  LOGGING_OUT,
}

/**
 * Service that handles the session management and fetches the basic information about the currently logged in user.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {

  private userInfoSubject = new BehaviorSubject<UserInfo>(null);
  private statusSubject = new BehaviorSubject<AuthorizeStatus>(AuthorizeStatus.LOGGED_OUT);
  private loggedInOnline = false;

  constructor(
    private http: HttpClient,
    private offlineModeService: OfflineModeService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private device: DeviceService,
    ) {
  }

  public isLoggedInOnline(): boolean {
    return this.loggedInOnline;
  }

  /**
   * Register an observer to listen for changes of the currently logged in user
   */
  public observeUser(): Observable<UserInfo> {
    this.offlineModeService.isOffline().then(offlineMode => {
      if (!offlineMode && this.userInfoSubject.value == null) {
        this.http.get<UserInfo>(`${environment.baseUrl}/api/user`).subscribe(ui => {
          this.updateUser(UserInfo.adapt(ui));
        });
      }
    });
    return this.userInfoSubject.asObservable();
  }

  /**
   * Register an observer to listen for changes of the current log-in-status
   */
  public observeStatus(): Observable<AuthorizeStatus> {
    return this.statusSubject.asObservable();
  }

  public getStatus(): AuthorizeStatus {
    return this.statusSubject.value;
  }

  private async fetchUser(): Promise<void> {
    const newUserInfo = await this.http.get<UserInfo>(`${environment.baseUrl}/api/user`).toPromise();
    this.loggedInOnline = true;
    this.updateUser(UserInfo.adapt(newUserInfo));
    this.updateStatus(AuthorizeStatus.LOGGED_IN);
  }

  private updateUser(newUser: UserInfo) {
    if (Utils.isDeepEqual(newUser, this.userInfoSubject.value)) {
      return;
    }
    this.userInfoSubject.next(newUser);
  }

  /**
   * Returns the currently logged in user.
   * Only executes an HTTP request if in online-mode and the user is not already cached.
   */
  public async getUserOnce(force: boolean = false): Promise<UserInfo> {
    let userInfo = this.userInfoSubject.value;
    if (userInfo == null || force) {
      const isOffline = await this.offlineModeService.isOffline();
      if (!isOffline) {
        await this.fetchUser();
      } else if (userInfo == null) {
        // Only throw an error if currently logged out and no last logged in user is available
        const lastLoggedInUserId = await this.offlineModeService.getLastLoggedInUser();
        if (lastLoggedInUserId == null) {
          throw new Error('Could not get user due to currently being in offline mode, logged out and having no last logged in user.');
        }
        const lastLoggedInUser = await this.offlineModeService.getCachedUser(lastLoggedInUserId);
        if (lastLoggedInUser == null) {
          throw new Error('Could not get user due to currently being in offline mode, logged out and the last logged in user not being cached.');
        }
        await this.signIn(lastLoggedInUser.email, lastLoggedInUser.password);
      }
    }
    return this.userInfoSubject.value;
  }

  private updateStatus(newStatus: AuthorizeStatus) {
    if (this.statusSubject.value === newStatus) {
      return;
    }
    this.statusSubject.next(newStatus);
  }

  /**
   * Handles the sign in.
   * @param email E-Mail of the user to sign in
   * @param password Password of the user to sign in
   */
  public async signIn(email: string, password: string): Promise<void> {
    this.updateStatus(AuthorizeStatus.LOGGING_IN);
    try {
      console.log('Signing in:', email);
      if (await this.offlineModeService.isOffline()) {
        const userCache = await this.offlineModeService.getUserCache();
        const userId = Object.keys(userCache).find((userId) => userCache[userId].email === email);
        if (userId == null) {
          const message = await this.translateService.get('COULD_NOT_FIND_USER_TO_LOG_IN_OFFLINE').toPromise<string>();
          Utils.showToast(this.device, this.toastController, message);
          return;
        }
        const userCacheEntry: UserCacheEntry = userCache[userId];
        if (userCacheEntry.password !== password) {
          const message = await this.translateService.get('PASSWORDS_DID_NOT_MATCH_CACHED_ONE').toPromise<string>();
          Utils.showToast(this.device, this.toastController, message);
          return;
        }
        const userInfo: UserInfo = new UserInfo(
          userId,
          userCacheEntry.teamId,
          userCacheEntry.email,
          userCacheEntry.firstName,
          userCacheEntry.lastName,
          userCacheEntry.roles,
        );
        this.updateUser(userInfo);
        this.loggedInOnline = false;
      } else {
        let body = new FormData();
        body.append('email', email);
        body.append('password', password);
        await this.http.post<void>(`${environment.baseUrl}/api/user/login`, body).toPromise();
        await this.fetchUser();
      }
    } catch (err) {
      this.localSignOut();
      throw err;
    } finally {
      if (this.statusSubject.value === AuthorizeStatus.LOGGING_IN) {
        this.updateStatus(this.userInfoSubject.value == null ? AuthorizeStatus.LOGGED_OUT : AuthorizeStatus.LOGGED_IN);
      }
    }
  }

  /**
   * Executes a sign-in without explicit credentials. The credentials are taken from cache.
   */
  public async automaticSignIn(): Promise<void> {
    this.updateStatus(AuthorizeStatus.LOGGING_IN);
    try {
      if (this.device.isBrowser()) {
        throw new Error('Tried signing in automatically but currently on web.');
      }
      const userId = await this.offlineModeService.getLastLoggedInUser();
      if (userId == null) {
        throw new Error('Tried signing in automatically but no user was logged in last.');
      }
      const cachedUser = await this.offlineModeService.getCachedUser(userId);
      if (cachedUser == null) {
        throw new Error('Tried signing in automatically but user was not previously cached.');
      }
      console.log('Signing in automatically:', cachedUser.email);
      const password = cachedUser.password;
      let body = new FormData();
      body.append('id', userId);
      body.append('password', password);
      await this.http.post<void>(`${environment.baseUrl}/api/user/login`, body).toPromise();
      await this.fetchUser();
    } finally {
      if (this.statusSubject.value === AuthorizeStatus.LOGGING_IN) {
        this.updateStatus(this.userInfoSubject.value == null ? AuthorizeStatus.LOGGED_OUT : AuthorizeStatus.LOGGED_IN);
      }
    }
  }

  /**
   * Handles the sign out.
   */
  public async signOut(): Promise<void> {
    this.updateStatus(AuthorizeStatus.LOGGING_OUT);
    try {
      if (!await this.offlineModeService.isOffline()) {
        await this.http.get<void>(`${environment.baseUrl}/api/user/logout`).toPromise();
      }
      this.localSignOut();
    } finally {
      if (this.statusSubject.value === AuthorizeStatus.LOGGING_OUT) {
        this.updateStatus(this.userInfoSubject.value == null ? AuthorizeStatus.LOGGED_OUT : AuthorizeStatus.LOGGED_IN);
      }
    }
  }

  /**
   * Handles the sign out only locally.
   * This is used by the remote sign out method and also when it is detected that the user was logged out remotely. 
   */
  public localSignOut(): void {
    this.loggedInOnline = false;
    this.updateUser(null);
    this.updateStatus(AuthorizeStatus.LOGGED_OUT);
    this.offlineModeService.setLastLoggedInUser(null);
  }
}
