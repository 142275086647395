import { Filterable } from '../shared/filterable';

export class TeamDetail implements Filterable {

    constructor(
        public id: string,
        public name: string,
        public memberIds: string[]) {
    }

    sort(other: TeamDetail): number {
        return this.name > other.name ? 1 : this.name < other.name ? -1 : 0;
    }

    filter(query: string): boolean {
        return this.name.toUpperCase().includes(query);
    }

    static adapt(objLiteral: TeamDetail): TeamDetail {
        if (objLiteral == null) {
            return null;
        }
        return new TeamDetail(
            objLiteral.id,
            objLiteral.name,
            objLiteral.memberIds
        );
    }
}
