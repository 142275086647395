import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserInfo } from '../models/user-info.model';
import { Lop } from '../models/lop.model';
import { UserCacheEntry } from '../models/user-cache-entry.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkSteps } from '../models/work-steps/work-steps.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Capacitor, Plugins, FilesystemDirectory } from '@capacitor/core';
import { Utils } from './utils';
import { Photo } from '../models/photo.model';
import { PendingOperation } from '../models/pending-operation.model';
import { TeamCacheEntry } from '../models/team-cache-entry.model';
import { TeamDetail } from '../models/team-detail.model';
import { DeviceService } from './device-service';
import { WindFarmInfo } from '../models/wind-farm-info.model';

const { Filesystem } = Plugins;

/**
 * Service that manages the offline-mode. Only this service accesses the storage to manage the caches for teams and users.
 */
@Injectable({
    providedIn: 'root'
})
export class OfflineModeService {
    static readonly OFFLINE_MODE: string = 'offline-mode';
    static readonly USER_CACHE: string = 'user-cache';
    static readonly TEAM_CACHE: string = 'team-cache';
    static readonly ALL_TEAMS_CACHE: string = 'all-teams-cache';
    static readonly WORK_STEPS_CACHE: string = 'worksteps-cache';
    static readonly WIND_FARM_INFO_CACHE: string = 'windfarminfo-cache';
    static readonly LOGIN_CACHE: string = 'login-cache';

    private _offlineModeSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private _pendingOpsSubject: BehaviorSubject<number> = new BehaviorSubject(0);

    workStepsCache = null;
    windFarmsAndTurbinesCache: WindFarmInfo = null;
    lopCache = null;
    allTeamsCache: TeamDetail[] = null;
    teamCache = null;
    userCache = null;
    pendingOperationsPerTeam = new Map<string, number>();
    pendingOperationsPerUser = new Map<string, number>();
    lastLoggedInUserId: string;
    measuresCache = null;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private device: DeviceService,
        ) {
    }

    async fetchWorkSteps(): Promise<WorkSteps> {
        return await this.http.get<WorkSteps>(`${environment.baseUrl}/api/work/lops/steps`).pipe(
            map(workSteps => WorkSteps.adapt(workSteps)),
        ).toPromise<WorkSteps>();
    }

    async fetchWindfarmsAndTurbines(): Promise<WindFarmInfo> {
        return await this.http.get<WindFarmInfo>(`${environment.baseUrl}/api/windfarms/turbines`).pipe(
            map(workSteps => WindFarmInfo.adapt(workSteps)),
        ).toPromise();
    }

    async getWorkSteps(): Promise<WorkSteps> {
        if (this.workStepsCache == null) {
            try {
                const isOffline = await this.isOffline();
                if (isOffline) {
                    const workSteps = await this.storage.get(OfflineModeService.WORK_STEPS_CACHE);
                    this.workStepsCache = WorkSteps.adapt(workSteps);
                } else {
                    const workSteps = await this.fetchWorkSteps();
                    await this.cacheWorkSteps(workSteps);
                }
            } catch (e) {
                console.log(e);
            }
        }
        return this.workStepsCache;
    }

    async getWindFarmsAndTurbines(): Promise<Map<string, string[]>> {
        if (this.windFarmsAndTurbinesCache == null) {
            try {
                const isOffline = await this.isOffline();
                if (isOffline) {
                    const windFarmsAndTurbines = await this.storage.get(OfflineModeService.WIND_FARM_INFO_CACHE);
                    this.windFarmsAndTurbinesCache = WindFarmInfo.adapt(windFarmsAndTurbines);
                } else {
                    const windFarmsAndTurbines = await this.fetchWindfarmsAndTurbines();
                    await this.cacheWindfarmsAndTurbines(windFarmsAndTurbines);
                }
            } catch (e) {
                console.log(e);
            }
        }
        return this.windFarmsAndTurbinesCache.windFarmsAndTurbines;
    }

    async cacheWorkSteps(workSteps: WorkSteps) {
        const workStepsObj = workSteps.toObject();
        await this.storage.set(OfflineModeService.WORK_STEPS_CACHE, workStepsObj);
        this.workStepsCache = workSteps;
        console.log('Cached work steps', workStepsObj);
    }

    async cacheWindfarmsAndTurbines(windFarmInfo: WindFarmInfo) {
        const windFarmInfoObj = windFarmInfo.toObject();
        await this.storage.set(OfflineModeService.WIND_FARM_INFO_CACHE, windFarmInfoObj);
        this.windFarmsAndTurbinesCache = windFarmInfo;
        console.log('Cached wind farms info', windFarmInfoObj);
    }

    observePendingOperations(): Observable<number> {
        return this._pendingOpsSubject.asObservable();
    }

    async getUserCache(): Promise<Object> {
        if (this.userCache == null) {
            try {
                let userCache = await this.storage.get(OfflineModeService.USER_CACHE);

                if (userCache == null) {
                    userCache = {};
                }
                const userIds = Object.keys(userCache);

                // Purge old entries that have no pending synchronization
                const dateOneMonthAgo = new Date();
                dateOneMonthAgo.setMonth(dateOneMonthAgo.getMonth() - 1);
                uceLoop:
                for (const userId of userIds) {
                    const userCacheEntry = userCache[userId] = UserCacheEntry.adapt(userCache[userId]);

                    // Check if last login is more recent than a month
                    if (userCacheEntry.timestampLastLogin.getTime() > dateOneMonthAgo.getTime()) {
                        continue;
                    }
                    // Check if user still has uncommitted changes (in any team because the user could have changed teams)
                    const teamCache = await this.getTeamCache();
                    for (const teamId of Object.keys(teamCache)) {
                        for (const pendingOperation of teamCache[teamId].pendingOperations) {
                            if (pendingOperation.userId === userId) {
                                continue uceLoop;
                            }
                        }
                    }
                    console.log(`Purged user cache entry for user id "${userId}" because it is old and has no pending operations.`);
                    delete userCache[userId];
                }

                this.userCache = userCache;
            } catch (e) {
                console.log(e);
            }
        }
        return this.userCache;
    }

    async setOffline(value: boolean) {
        if (!this.device.isApp()) {
            return;
        }
        this._offlineModeSubject.next(value);
        try {
            await this.storage.set(OfflineModeService.OFFLINE_MODE, value);
        } catch (e) {
            console.log(e);
        }
    }

    async isOffline(): Promise<boolean> {
        if (this._offlineModeSubject.value == null) {
            if (this.device.isApp()) {
                try {
                    const offlineMode = await this.storage.get(OfflineModeService.OFFLINE_MODE);
                    if (offlineMode == null) {
                        this._offlineModeSubject.next(false);
                    } else {
                        this._offlineModeSubject.next(offlineMode);
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                this._offlineModeSubject.next(false);
            }
        }
        return this._offlineModeSubject.value;
    }

    observeOfflineMode(): Observable<boolean> {
        if (this._offlineModeSubject.value == null) {
            if (this.device.isApp()) {
                this.storage.get(OfflineModeService.OFFLINE_MODE).then(offlineMode => {
                    if (offlineMode == null) {
                        this._offlineModeSubject.next(false);
                    } else {
                        this._offlineModeSubject.next(offlineMode);
                    }
                }).catch(e => console.log(e));
            } else {
                this._offlineModeSubject.next(false);
            }
        }
        return this._offlineModeSubject.asObservable();
    }

    async cacheAssignedLops(lops: Lop[], teamId: string) {
        let teamCache = await this.getTeamCache();
        let teamCacheEntry: TeamCacheEntry;
        await this.persistPicturesOfLops(lops, teamId);
        if (teamCache.hasOwnProperty(teamId)) {
            teamCacheEntry = teamCache[teamId];
            if (teamCacheEntry.pendingOperations.length > 0) {
                throw new Error('Cannot update team cache with pending operations.');
            }
            teamCacheEntry.lops = lops;
            teamCacheEntry.timestampLastLopsUpdate = new Date();
        } else {
            teamCacheEntry = {
                lops: lops,
                pendingOperations: [],
                timestampLastLopsUpdate: new Date(),
            };
            teamCache[teamId] = teamCacheEntry;
        }
        await this.storage.set(OfflineModeService.TEAM_CACHE, teamCache);
    }

    private async persistPicturesOfLops(lops: Lop[], teamId: string) {
        await Filesystem.rmdir({
            path: teamId,
            directory: FilesystemDirectory.Data,
            recursive: true,
        }).catch(err => {
            console.log(`Removing the picture-directory for team ${teamId} failed:`, err);
        });
        for (const lop of lops) {
            console.log('Persisting pics of LOP: ', lop);
            await this.persistPictures(lop.photos, teamId + '/' + lop.id);
        }
    }

    private async persistPictures(photos: Photo[], path: string) {
        for (const photo of photos) {
            // Check if photo already persisted in filesystem
            if (photo.persistedAtPath === null || photo.persistedAtPath === undefined) {
                // Write the file to the filesystem (the data directory)
                const fileName = (photo.id ?? Utils.uuid()) + '.jpeg';
                const filePath = path + '/' + fileName;
                console.log(`Persisting pic with ID ${photo.id} to ${filePath}`);
                const savedFile = await Filesystem.writeFile({
                    path: filePath,
                    data: Utils.dataUriToBase64String(photo.data),
                    directory: FilesystemDirectory.Data,
                    recursive: true,
                });

                photo.data = null;
                photo.persistedAtPath = filePath;
                photo.webviewPath = Capacitor.convertFileSrc(savedFile.uri);
            }
        }
    }

    async getLops(teamId: string): Promise<Lop[]> {
        const teamCache = await this.getTeamCache();
        if (!teamCache.hasOwnProperty(teamId)) {
            console.warn('Team of user was not previously cached');
            return [];
        }
        return teamCache[teamId].lops;
    }

    /**
     * Returns the lop or null if lop was not found
     * @param lopId id of the lop
     */
    async getLop(lopId: string): Promise<Lop> {
        const teamCache = await this.getTeamCache();
        const teamIds = Object.keys(teamCache);
        for (const teamId of teamIds) {
            const teamCacheEntry: TeamCacheEntry = teamCache[teamId];
            const lop = teamCacheEntry.lops.find(lop => lop.id === lopId);
            if(lop != null){
                return lop;
            }
        }
        return null;
    }

    async getTeams(): Promise<TeamDetail[]> {
        return await this.getAllTeamsCache();
    }

    private async updatePendingOperationsCount() {
        this.pendingOperationsPerTeam.clear();
        this.pendingOperationsPerUser.clear();
        let pendingOperationCount = 0;
        const teamCache = await this.getTeamCache();
        const teamIds = Object.keys(teamCache);
        teamIds.forEach(teamId => {
            const pendOps = (teamCache[teamId] as TeamCacheEntry).pendingOperations.length;
            this.pendingOperationsPerTeam.set(teamId, pendOps);
            pendingOperationCount += pendOps;
        });
        const userCache = await this.getUserCache();
        const userIds = Object.keys(userCache);
        userIds.forEach(userId => {
            const pendingOperations = (userCache[userId] as UserCacheEntry).pendingOperations.length;
            this.pendingOperationsPerUser.set(userId, pendingOperations);
            pendingOperationCount += pendingOperations;
        });
        this._pendingOpsSubject.next(pendingOperationCount);
    }

    async getTeamCache(): Promise<Object> {
        if (this.teamCache == null) {
            let teamCache = await this.storage.get(OfflineModeService.TEAM_CACHE);
            if (teamCache == null) {
                teamCache = {};
            }
            const teamIds = Object.keys(teamCache);
            teamIds.forEach(teamId => teamCache[teamId] = TeamCacheEntry.adapt(teamCache[teamId]));
            this.teamCache = teamCache;
            await this.updatePendingOperationsCount();
        }
        return this.teamCache;
    }

    async getAllTeamsCache(): Promise<TeamDetail[]> {
        if (this.allTeamsCache == null) {
            let allTeams: TeamDetail[] = await this.storage.get(OfflineModeService.ALL_TEAMS_CACHE);
            if (allTeams == null) {
                allTeams = [];
            }
            allTeams = allTeams.map(team => TeamDetail.adapt(team));
            allTeams.sort((team1, team2) => team1.sort(team2));
            this.allTeamsCache = allTeams;
        }
        return this.allTeamsCache;
    }

    async persistTeamCache() {
        if (this.teamCache == null) {
            throw new Error('Team-cache is null and cannot be persisted');
        }
        try {
            await this.storage.set(OfflineModeService.TEAM_CACHE, this.teamCache);
        } catch (e) {
            console.log(e);
        }
    }

    async persistUserCache() {
        if (this.userCache == null) {
            throw new Error('User-cache is null and cannot be persisted');
        }
        try {
            await this.storage.set(OfflineModeService.USER_CACHE, this.userCache);
        } catch (e) {
            console.log(e);
        }
    }

    async persistAllTeamsCache(newTeamsCache: TeamDetail[] = null) {
        if (newTeamsCache != null) {
            this.allTeamsCache = newTeamsCache;
        } else if (this.allTeamsCache == null) {
            throw new Error('AllTeams-cache is null and cannot be persisted');
        }
        try {
            await this.storage.set(OfflineModeService.ALL_TEAMS_CACHE, this.allTeamsCache);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Persisting pending operations to local database while filtering all the ones with the type set to NULL.
     * This method is used to save changes after an execution of a pending operation.
     */
    async persistPendingOperations() {
        if (this.teamCache == null) {
            throw new Error('Team-cache is null and its pending operations cannot be persisted');
        }
        if (this.userCache == null) {
            throw new Error('User-cache is null and its pending operations cannot be persisted');
        }
        const teamIds = Object.keys(this.teamCache);
        teamIds.forEach(teamId => {
            const teamCacheEntry: TeamCacheEntry = this.teamCache[teamId];
            teamCacheEntry.pendingOperations = teamCacheEntry.pendingOperations.filter(pendingOperation => pendingOperation.type != null);
        });
        await this.persistTeamCache();
        const userIds = Object.keys(this.userCache);
        userIds.forEach(userId => {
            const userCacheEntry: UserCacheEntry = this.userCache[userId];
            userCacheEntry.pendingOperations = userCacheEntry.pendingOperations.filter(pendingOperation => pendingOperation.type != null);
        });
        await this.persistUserCache();
        await this.updatePendingOperationsCount();
    }

    /**
     * Remembers the logged in user id for further re-logins.
     */
    async setLastLoggedInUser(userId: string): Promise<void> {
        this.lastLoggedInUserId = userId;
        await this.storage.set(OfflineModeService.LOGIN_CACHE, userId).catch(e => console.log(e));
    }

    async getLastLoggedInUser(): Promise<string> {
        if (this.lastLoggedInUserId === undefined) {
            this.lastLoggedInUserId = await this.storage.get(OfflineModeService.LOGIN_CACHE).catch(e => console.log(e));
        }
        return this.lastLoggedInUserId;
    }

    async getCachedUser(userId: string): Promise<UserCacheEntry> {
        const userCache = await this.getUserCache();
        if (!userCache.hasOwnProperty(userId)) {
            return null;
        }
        return userCache[userId];
    }

    /**
     * Adds the used E-Mail to the E-Mail cache for account switching.
     * Removes user caches that are older than a month AND have no pending synchronization to do.
     */
    async updateUserCache(userInfo: UserInfo, password: string) {
        const dateNow = new Date();

        const userCache = await this.getUserCache();

        // Purge possibly old entry with same email but another ID
        const oldUserId = Object.keys(userCache).find(userId => userCache[userId].email === userInfo.email);
        if (oldUserId != null) {
            delete userCache[oldUserId];
        }

        const userCacheEntry: UserCacheEntry = {
            email: userInfo.email,
            password: password,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            teamId: userInfo.teamId,
            roles: userInfo.roles,
            timestampLastLogin: dateNow,
            pendingOperations: [],
        };
        userCache[userInfo.id] = userCacheEntry;
        await this.persistUserCache();
    }

    async updateUserPasswordCache(password: string) {
        const lastUserId = await this.getLastLoggedInUser();
        const userCache = await this.getUserCache();
        if (!userCache.hasOwnProperty(lastUserId)) {
            console.warn('Tried updating password but could not find last logged in user in user cache.', lastUserId, userCache);
            return;
        }
        const userCacheEntry = userCache[lastUserId];
        userCacheEntry.password = password;
        console.log('Updating cached password for user', lastUserId, userCache);
        await this.persistUserCache();
    }

    async addPendingOperation(pendingOperation: PendingOperation, teamId: string) {
        pendingOperation.timestamp = new Date();
        let photos: Photo[] = [];
        if (pendingOperation.payload.hasOwnProperty('photos') && pendingOperation.payload['photos'] != null) {
            photos.push(...pendingOperation.payload['photos']);
        }
        if (pendingOperation.payload.hasOwnProperty('lopProgression') && pendingOperation.payload['lopProgression'] != null && pendingOperation.payload['lopProgression'].photos != null) {
            photos.push(...pendingOperation.payload['lopProgression'].photos);
        }
        if (photos.length > 0) {
            // Set ID to null to reset already uploaded photos so they get uploaded again and the server might
            // not reject them because they are already attached and the server can re-execute the operation if necessary
            photos.forEach(photo => photo.id = null);
            await this.persistPictures(photos, 'PO-' + pendingOperation.userId + '-' + pendingOperation.timestamp.getTime());
        }

        if (teamId == null) {
            const userCache = await this.getUserCache();
            if (!userCache.hasOwnProperty(pendingOperation.userId)) {
                console.warn('Tried adding pending operation but user not found in user cache.', pendingOperation.userId, userCache);
                return;
            }
            const userCacheEntry: UserCacheEntry = userCache[pendingOperation.userId];
            userCacheEntry.pendingOperations.push(pendingOperation);
            await this.persistUserCache();

            this.increasePendingOperationCountOfUser(pendingOperation.userId);

        } else {
            const teamCache = await this.getTeamCache();
            const teamCacheEntry: TeamCacheEntry = teamCache[teamId];

            teamCacheEntry.pendingOperations.push(pendingOperation);
            await this.persistTeamCache();

            this.increasePendingOperationCountOfTeam(teamId);
        }
    }

    async discardAllPendingOperations() {
        const teamCache = await this.getTeamCache();
        const teamIds = Object.keys(teamCache);
        teamIds.forEach(teamId => {
            const tce: TeamCacheEntry = teamCache[teamId];
            tce.pendingOperations = [];
            this.pendingOperationsPerTeam.set(teamId, 0);
        });
        await this.persistTeamCache();

        const userCache = await this.getUserCache();
        const userIds = Object.keys(userCache);
        userIds.forEach(userId => {
            const userCacheEntry: UserCacheEntry = userCache[userId];
            userCacheEntry.pendingOperations = [];
            this.pendingOperationsPerUser.set(userId, 0);
        });
        await this.persistUserCache();

        this._pendingOpsSubject.next(0);
    }

    async getAllPendingOperations(sortByTimestamp: boolean = false): Promise<PendingOperation[]>{
        let allPendingOps: PendingOperation[] = [];

        const teamCache = await this.getTeamCache();
        const teamIds = Object.keys(teamCache);
        for (const teamId of teamIds) {
            const teamCacheEntry: TeamCacheEntry = teamCache[teamId];
            allPendingOps = allPendingOps.concat(teamCacheEntry.pendingOperations);
        }

        const userCache = await this.getUserCache();
        const userIds = Object.keys(userCache);
        for (const userId of userIds) {
            const userCacheEntry: UserCacheEntry = userCache[userId];
            allPendingOps = allPendingOps.concat(userCacheEntry.pendingOperations);
        }

        if(sortByTimestamp){
            allPendingOps.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
        }
        return allPendingOps;
    }

    /**
     * Returns the first recorded pending operation
     * @returns pending operation with oldest timestamp
     */
    async getFirstPendingOperation(): Promise<PendingOperation>{
        let allPendingOps: PendingOperation[] = await this.getAllPendingOperations(true);
        return allPendingOps[0];
    }

    /**
     * Discard all pending operations for a specific lop
     * @param lopId LopId to discard all pending operations for
     */
    async discardPendingOperationsForLop(lopId: string): Promise<void>{
        const teamCache = await this.getTeamCache();
        const teamIds = Object.keys(teamCache);
        let numberOfPendingOperations: number = 0;
        try{
            teamIds.forEach(teamId => {
                const tce: TeamCacheEntry = teamCache[teamId];
                tce.pendingOperations = tce.pendingOperations.filter(po => po.payload['lopId'] != lopId);
                tce.lops = tce.lops.filter(lop => lop.id != lopId);
                this.pendingOperationsPerTeam.set(teamId, tce.pendingOperations.length);
                numberOfPendingOperations = numberOfPendingOperations + tce.pendingOperations.length;
            });
            await this.persistTeamCache();
            this._pendingOpsSubject.next(numberOfPendingOperations);

            const userCache = await this.getUserCache();
            const userIds = Object.keys(userCache);
            userIds.forEach(userId => {
                const userCacheEntry: UserCacheEntry = userCache[userId];
                userCacheEntry.pendingOperations = userCacheEntry.pendingOperations.filter(po => po.payload['lopId'] != lopId);
                this.pendingOperationsPerUser.set(userId, userCacheEntry.pendingOperations.length);
                numberOfPendingOperations = numberOfPendingOperations + userCacheEntry.pendingOperations.length;
            });
            await this.persistUserCache();
            this._pendingOpsSubject.next(numberOfPendingOperations);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    private increasePendingOperationCountOfTeam(teamId: string) {
        let pendingOperationsCountOfTeam = this.pendingOperationsPerTeam.get(teamId);
        if (pendingOperationsCountOfTeam == null) {
            pendingOperationsCountOfTeam = 1;
        } else {
            pendingOperationsCountOfTeam++;
        }
        this.pendingOperationsPerTeam.set(teamId, pendingOperationsCountOfTeam);
        this._pendingOpsSubject.next(this._pendingOpsSubject.value + 1);
    }

    private increasePendingOperationCountOfUser(userId: string) {
        let pendingOperationsCountOfUser = this.pendingOperationsPerUser.get(userId);
        if (pendingOperationsCountOfUser == null) {
            pendingOperationsCountOfUser = 1;
        } else {
            pendingOperationsCountOfUser++;
        }
        this.pendingOperationsPerUser.set(userId, pendingOperationsCountOfUser);
        this._pendingOpsSubject.next(this._pendingOpsSubject.value + 1);
    }
}
